import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230623-Rate-Sheet.pdf";
const date = "23.06.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p><strong>Following the Monetary Policy Committee announcement on Thursday 22 June 2023, the Bank of England Base Rate has increased from 4.5% to 5.0%. </strong></p>
    <p>With effect from Friday 23 June 2023 the changes are as follows:</p>
    <ul>
      <li>All tracker rates will include the increased Bank of England Base Rate.</li>
      <li>There are no changes to our Residential Standard Variable Rate or Buy to Let Standard Variable Rate.</li>
    </ul>
    <p>An updated mortgage rate sheet will be published on Friday 23 June 2023 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
